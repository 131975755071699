import { api } from "../services/api";
import { displayErrMsg } from "../utils/errors";
import router from "../router";

export default {
    namespaced: true,

    state: {
        monitorings: [],
        monitoring: {},
        exclusions: [],
        monitoringsAlerts: [],
    },

    getters: {
        monitorings: (state) => state.monitorings,
        monitoring: (state) => state.monitoring,
        exclusions: (state) => state.exclusions,
        monitoringsAlerts: (state) => state.monitoringsAlerts,
    },

    mutations: {
        async GET_MONITORINGS(state, { data }){
            await api
            .get(`geofences/${data.customer_id}/schedules`)
            .then((res) => {
                state.monitorings = res.data
            })
            .catch((err) => {
                displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'GET_MODELS');
            })
        },
        async GET_SCHEDULE_ALERTS(state, { data }){
            await api
            .get(`geofences/${data.customer_id}/schedules/logs`)
            .then((res) => {
                state.monitoringsAlerts = res.data
            })
            .catch((err) => {
                displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'GET_MODELS');
            })
        },
        async SET_SCHEDULE_ALERTS_REASON(state, { data }){
            await api
            .put(`geofences/${data.customer_id}/schedules/logs`, data.alerts_list)
            .then((res) => {
                displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Pessoas na Linha', 'UPDATE_MONITORING');
            })
            .catch((err) => {
                displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'UPDATE_MONITORING');
            })
        },
        async CHANGE_MONITORING_STATE(state, { data }){
            await api
            .patch(`geofences/${data.customer_id}/schedules`, data.monitoring_status)
            .then(() => {
                let stateMonitoring = state.monitorings.filter((monitoring) => monitoring.id === data.monitoring_status.id)[0]
                stateMonitoring.status = data.monitoring_status.status
                
                displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Pessoas na Linha', 'CHANGE_MONITORING_STATE');
            })
            .catch((err) => {
                displayErrMsg(err.response.status, 'Pessoas na Linha', 'GET_MODELS');
            })
        },

        async CREATE_MONITORING(state, { data }) {
            await api
            .post(`geofences/${data.customer_id}/schedules`, data.monitoring)
            .then((res) => {
                displayErrMsg("GLOBAL_SUCCESS_ADD", 'Pessoas na Linha', 'CHANGE_MONITORING_STATE');
                router.push({ name: 'customer-edit' })
            })
            
            .catch((err) => {
                displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'CREATE_MONITORING');
            })
        },

        async UPDATE_MONITORING(state, { data }) {
            await api
            .put(`geofences/${data.customer_id}/schedules`, data.monitoring)
            .then((res) => {
                displayErrMsg("GLOBAL_SUCCESS_MODIFY", 'Pessoas na Linha', 'UPDATE_MONITORING');
                router.push({ name: 'customer-edit' })
            })
            .catch((err) => {
                if (err.response.data.code === 409 && err.response.data.message.includes("geofence-schedule-updt-conflict-exclusion-start")){
                    displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'UPDATE_MONITORING', {
                            reason: "não contempla as exclusões já cadastradas."
                        }
                    );
                }

                else {
                    displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'UPDATE_MONITORING');
                }
            })
        },

        async DELETE_MONITORING(state, { data }){
            await api
            .delete(`geofences/${data.customer_id}/schedules?schedule_ids=${data.monitoring_id}`)
            .then((res) => {
                state.monitorings = state.monitorings.filter((monitoring) => monitoring.id !== data.monitoring_id)

                displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Pessoas na Linha', 'DELETE_MONITORING');
            })
            .catch((err) => {
                if (err.response.data.code === 409 && err.response.data.message.includes("geofence-geofence-schedule-association-exists")){
                    displayErrMsg(err.response.status, 'Pessoas na Linha', 'DELETE_MONITORING', {
                        reason: "está associado a uma geofence"
                    });
                }

                else {
                    displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'DELETE_MONITORING');
                }
            })
        },

        async GET_MONITORING(state, { data }) {
            await api
            .get(`geofences/${data.customer_id}/schedules?id=${data.monitoring_id}`)
            .then((res) => {
                state.monitoring = res.data[0]
            })
            .catch((err) => {
                displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'GET_MONITORING');
            })
        },
        async GET_MONITORING_EXCLUSIONS(state, { data }){
            await api
            .get(`geofences/schedules/exclusions?schedule_id=${data.schedule_id}`).then((res) => {
                state.exclusions = [];

                res.data.map((exclusion) => {
                    state.exclusions = [...state.exclusions, {...exclusion}]
                })
            })
            .catch((err) => {
                displayErrMsg(err.response.data.code, 'Pessoas na Linha', 'GET_MODELS');
            })
        },
        async CREATE_MONITORING_EXCLUSION(state, { data }){
            await api
            .post(`geofences/schedules/exclusions?schedule_id=${data.schedule_id}`, {
                exclusions: data.exclusion_list
            })
            .then((res) => {
                let stateMonitoring = state.monitorings.filter((monitoring) => monitoring.id === data.schedule_id)[0]
                stateMonitoring.exclusions.push(res.data)
                state.exclusions.push(res.data)
                displayErrMsg("GLOBAL_SUCCESS_ADD", 'Exceção - Pessoas na Linha', 'UNASSIGN_MONITORING_GEOFENCE');
            })
            .catch((err) => {
                displayErrMsg(err.response.status, 'Exceção - Pessoas na Linha', 'GLOBAL_DEFAULT');
            });
        },
        async ASSIGN_MONITORING_GEOFENCE(state, { data }){
            let schedule_id = data.schedule.id

            await api
            .patch(`geofences/${schedule_id}/assign`, {
                geofence_ids: data.geofence_ids
            })

            .then((res) => {
                state.monitorings.geofences = state.monitorings.geofences ? state.monitorings.geofences : [];
                state.monitorings.geofences.push(data.geofence_ids);
                displayErrMsg("ASSIGN_MONITORING_GEOFENCE", 'Pessoas na Linha', 'ASSIGN_GEOFENCE_MONITORING');
            })
            .catch((err) => {
                displayErrMsg(err.response.status, 'Pessoas na Linha', 'GLOBAL_DEFAULT');
            });
        },
        async UNASSIGN_MONITORING_GEOFENCE(state, { data }){
            let schedule_id = data.schedule.id

            await api
            .patch(`geofences/${schedule_id}/unassign`, {
                geofence_ids: data.geofence_ids
            })

            .then((res) => {
                // state.trainings.push(data);
                displayErrMsg("UNASSIGN_TRAINING_GEOFENCE", 'Pessoas na Linha', 'UNASSIGN_MONITORING_GEOFENCE');
            })
            .catch((err) => {
                displayErrMsg(err.response.status, 'Pessoas na Linha', 'GLOBAL_DEFAULT');
            });
        },
        
        async DELETE_MONITORING_EXCLUSION(state, { data }){
            await api
            .delete(`geofences/schedules/exclusions?exclusion_ids=${data.exclusion_id}`)
            .then((res) => {
                let monitoringState = state.monitorings.filter((monitoring) => monitoring.id === data.monitoring_id)[0]
                monitoringState.exclusions = monitoringState.exclusions.
                filter((exclusion) => exclusion.id !== data.exclusion_id)

                displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Exceção - Pessoas na Linha', 'DELETE_MONITORING_EXCLUSION');
            })
            .catch((err) => {
                displayErrMsg(err.response.data.code, 'Exceção - Pessoas na Linha', 'DELETE_MONITORING_EXCLUSION');
            })
        },
    },

    actions: {
        getScheduleAlerts(context, data){
            context.commit("GET_SCHEDULE_ALERTS", { data })
        },
        setScheduleAlertsReason(context, data){
            context.commit("SET_SCHEDULE_ALERTS_REASON", { data })
        },
        getMonitorings(context, data){
            context.commit("GET_MONITORINGS", { data })
        },
        changeMonitoringState(context, data){
            context.commit("CHANGE_MONITORING_STATE", { data })
        },
        createMonitoring(context, data){
            context.commit("CREATE_MONITORING", { data })
        },
        updateMonitoring(context, data){
            context.commit("UPDATE_MONITORING", { data })
        },
        deleteMonitoring(context, data){
            context.commit("DELETE_MONITORING", { data })
        },
        getMonitoring(context, data){
            context.commit("GET_MONITORING", { data })
        },
        assignMonitoringGeofence(context, data){
            context.commit("ASSIGN_MONITORING_GEOFENCE", { data })
        },
        unassignMonitoringGeofence(context, data){
            context.commit("UNASSIGN_MONITORING_GEOFENCE", { data })
        },
        getMonitoringExclusions(context, data){
            context.commit("GET_MONITORING_EXCLUSIONS", { data })
        },
        createMonitoringExclusion(context, data){
            context.commit("CREATE_MONITORING_EXCLUSION", { data })
        },
        deleteMonitoringExclusion(context, data){        
            context.commit("DELETE_MONITORING_EXCLUSION", { data })
        }
    }
}