import Vue from "vue";
import Router from "vue-router";

import auth from "./auth";
import hasRole from "./utils/hasRole";

import Login from "./views/Login/Login.vue";
import ForgotPass from "./views/Login/ForgotPassword.vue";
import ConfirmLogin from "./views/Login/ConfirmLogin.vue";
import ConfirmReset from "./views/Login/ConfirmReset.vue";
import FirstLogin from "./views/Login/FirstLogin.vue";

import CustomerConfig from "./views/CustomerConfig/CustomerConfig.vue";
import CustomerConfigImport from "./views/CustomerConfig/Import.vue";
import TimersTab from "./views/Customer/components/TimersTab.vue";

import User from "./views/User/Manager.vue";
import UserAdd from "./views/User/Add.vue";
import UserEdit from "./views/User/Edit.vue";
import NoFrontend from "./views/User/NoFrontend.vue";

import Customer from "./views/Customer/Manager.vue";
import CustomerAdd from "./views/Customer/Add.vue";
import CustomerEdit from "./views/Customer/Edit.vue";

import CustomerAddEmployee from "./views/Employee/Add.vue";
import CustomerEditEmployee from "./views/Employee/Edit.vue";
import EmployeeAddTags from "./views/Employee/AddTags.vue";
import CustomerAddTags from "./views/Customer/AddTags.vue";
import CustomerAddGateways from "./views/Customer/AddGateways.vue";
import CustomerImportEmployee from "./views/Employee/Import.vue";
import CustomerUpdateTrainingsEmployee from "./views/Employee/UpdateTraining.vue";

import CustomerAddAsset from "./views/Asset/Add.vue";
import CustomerEditAsset from "./views/Asset/Edit.vue";
import AssetAddTags from "./views/Asset/AddTags.vue";
import CustomerImportAsset from "./views/Asset/Import.vue";
import AssetMaintenance from "./views/Asset/MaintenancePage.vue";

import CustomerAddContact from "./views/Contact/Add.vue";
import CustomerEditContact from "./views/Contact/Edit.vue";

import CustomerAddBuilding from "./views/Building/Add.vue";
import CustomerEditBuilding from "./views/Building/Edit.vue";

import BuildingAddFloor from "./views/Floor/Add.vue";
import BuildingEditFloor from "./views/Floor/Edit.vue";

import Gateway from "./views/Gateway/Manager.vue";
import GatewayAdd from "./views/Gateway/Add.vue";
import GatewayStatus from "./views/Gateway/StatusGateway.vue";
import GatewayEdit from "./views/Gateway/Edit.vue";
import GatewayImport from "./views/Gateway/Import.vue";
import GatewayConfig from "./views/Gateway/GatewayConfig.vue";

import Tag from "./views/Tag/Manager.vue";
import TagAdd from "./views/Tag/Add.vue";
import TagEdit from "./views/Tag/Edit.vue";
import TagImport from "./views/Tag/Import.vue";

import TrainingAdd from "./views/Training/Add.vue";
import TrainingEdit from "./views/Training/Edit.vue";
import TrainingImport from "./views/Training/Import.vue";

import Group from "./views/Group/Manager.vue";
import GroupAdd from "./views/Group/Add.vue";
import GroupEdit from "./views/Group/Edit.vue";
import GroupConfig from "./views/Group/GroupConfig.vue";

import Dashboard from "./views/Dashboard/Index.vue";

import AddNotificationRule from "./views/NotificationRule/Add.vue";
import EditNotificationRule from "./views/NotificationRule/Edit.vue";

import Notification from "./views/Notification/Manager.vue";
import NotificationAdd from "./views/Notification/Add.vue";
import NotificationEdit from "./views/Notification/Edit.vue";

import Inventario from "./views/Inventario/Manager.vue";

import Unauthorized from "./views/401.vue";
import LoadingPage from "./components/LoadingPage.vue";
import CanaisAtendimento from "./views/CommunicationChannels.vue"

import ManagerPlatform from "./views/Platform/Manager.vue";
import EditPlatform from "./views/Platform/Edit.vue";

import AddMonitoring from "./views/Monitoring/Add.vue";
import EditMonitoring from "./views/Monitoring/Edit.vue";

Vue.use(Router);

function requireAuth(to, from, next) {
  if (!auth.loggedIn()) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

function requireRole(to, from, next) {
  to.matched.forEach((rec) => {
    if (rec.meta.requiredRole) {
      const can = hasRole(rec.meta.requiredRole);
      if (can) {
        next();
      } else {
        next("/401");
      }
    } else {
      next();
    }
  });
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/first-login",
      name: "first-login",
      component: FirstLogin,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/confirm-login",
      name: "confirm-login",
      component: ConfirmLogin,
    },
    {
      path: "/NoFrontend",
      name: "NoFrontend",
      component: NoFrontend,
    },
    {
      path: "/recover-pass",
      name: "forgot-password",
      component: ForgotPass,
    },
    {
      path: "/confirm-reset",
      name: "reset-password",
      component: ConfirmReset,
    },
    {
      path: "/user",
      name: "user",
      component: User,
      beforeEnter: requireAuth,
    },
    {
      path: "/user/add",
      name: "user-add",
      component: UserAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "user", label: "Usuários", link: "/user" },
          { name: "user-add", label: "Adicionar usuário" },
        ],
      },
    },
    {
      path: "/user/edit/:id?",
      name: "user-edit",
      component: UserEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "user", label: "Usuários", link: "/user" },
          { name: "user-edit", label: "Editar usuário" },
        ],
      },
    },
    {
      path: "/inventario",
      name: "inventario",
      component: Inventario,
      beforeEnter: requireAuth,
    },
    {
      path: "/customer",
      name: "customer",
      component: Customer,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: [
          "ADMIN",
          "SYSADMIN",
          "MANAGER",
          "DASHBOARD",
          "FRONTDESK",
          "USER",
        ],
      },
    },
    {
      path: "/customer/add",
      name: "customer-add",
      component: CustomerAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          { name: "customer-add", label: "Adicionar cliente" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/customer/:customerId/edit",
      name: "customer-edit",
      component: CustomerEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          { label: "Editar cliente" },
        ],
        requiredRole: [
          "ADMIN",
          "SYSADMIN",
          "MANAGER",
          "DASHBOARD",
          "FRONTDESK",
          "USER",
        ],
      },
    },
    {
      path: "/customer/:customerId/employee/import",
      name: "customer-import-employee",
      component: CustomerImportEmployee,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Importar colaboradores" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "DASHBOARD", "USER"],
      },
    },
    {
      path: "/customer/:customerId/employee/update",
      name: "customer-update-training-employee",
      component: CustomerUpdateTrainingsEmployee,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Atualizar treinamentos dos colaboradores" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "DASHBOARD", "USER"],
      },
    },
    {
      path: "/customer/:customerId/employee/:employeeId/edit",
      name: "customer-employee-edit",
      component: CustomerEditEmployee,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar colaborador" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER", "FRONTDESK", "DASHBOARD", "USER"],
      },
    },
    {
      path: "/customer/:customerId/employee/:employeeId/add-tags",
      name: "customer-employee-add-tags",
      component: EmployeeAddTags,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          {
            name: "customer-employee-edit",
            label: "",
            link: "/customer/:customerId/employee/:employeeId/edit",
          },
          { label: "Associar Tags" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN", "DASHBOARD", "USER"],
      },
    },
    {
      path: "/customer/:customerId/training/add",
      name: "customer-add-training",
      component: TrainingAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar Treinamentos" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN", "DASHBOARD"],
      },
    },
    {
      path: "/customer/:customerId/training/:trainingId/edit",
      name: "customer-edit-training",
      component: TrainingEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar Treinamento" },
        ],
        requiredRole: ['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER'],
      },
    },
    {
      path: "/customer/:customerId/training/import",
      name: "customer-import-training",
      component: TrainingImport,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Importar Treinamento" },
        ],
        requiredRole: ['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER'],
      },
    },
    {
      path: "/customer/:customerId/asset/:assetId/add-tags",
      name: "customer-asset-add-tags",
      component: AssetAddTags,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Associar Tags" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      },
    },
    {
      path: "/customer/:customerId/asset/:assetId/maintenance-history",
      name: "customer-asset-maintenance-history",
      component: AssetMaintenance,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Página de Manutenções" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      },
    },
    {
      path: "/customer/:customerId/employee/add",
      name: "customer-employee-add",
      component: CustomerAddEmployee,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar colaborador" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN", "DASHBOARD", "USER"],
      },
    },
    {
      path: "/customer/:customerId/add-tags",
      name: "customer-add-tags",
      component: CustomerAddTags,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Associar Tags" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN", "DASHBOARD", "USER"],
      },
    },
    {
      path: "/customer/:customerId/asset/add",
      name: "customer-asset-add",
      component: CustomerAddAsset,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar ativo" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      }
    },
    {
      path: "/customer/:customerId/asset/:assetId/edit",
      name: "customer-asset-edit",
      component: CustomerEditAsset,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar ativo" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      }
    },
    {
      /* import customer-asset-import */
      path: "/customer/:customerId/asset/import",
      name: "customer-asset-import",
      component: CustomerImportAsset,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Importar ativos" },
        ],
        requiredRole: ["ADMIN", "FRONTDESK", "SYSADMIN"],
      }
    },
    {
      path: "/customer/:customerId/add-gateways",
      name: "customer-add-gateways",
      component: CustomerAddGateways,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Associar Coletores" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN"],
      },
    },
    {
      path: "/customer/:customerId/contact/add",
      name: "customer-contact-add",
      component: CustomerAddContact,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar contato" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/customer/:customerId/contact/:contactId/edit",
      name: "customer-contact-edit",
      component: CustomerEditContact,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar contato" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER", "FRONTDESK"],
      },
    },
    {
      path: "/customer/:customerId/building/add",
      name: "customer-building-add",
      component: CustomerAddBuilding,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar edíficio" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER", "DASHBOARD"],
      },
    },

    {
      path: "/customer/:customerId/building/:buildingId/edit",
      name: "customer-building-edit",
      component: CustomerEditBuilding,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar edíficio" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER", "DASHBOARD", "USER"],
      },
    },

    {
      path: "/customer/:customerId/building/:buildingId/floor/add",
      name: "building-floor-add",
      component: BuildingAddFloor,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          {
            name: "customer-building-edit",
            label: "",
            link: "/customer/:customerId/building/:buildingId/edit",
          },
          { label: "Adicionar andar" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER", "DASHBOARD", "USER"],
      },
    },

    {
      path: "/customer/:customerId/building/:buildingId/floor/:floorId/edit",
      name: "building-floor-edit",
      component: BuildingEditFloor,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          {
            name: "customer-building-edit",
            label: "",
            link: "/customer/:customerId/building/:buildingId/edit",
          },
          { label: "Editar andar" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER", "DASHBOARD", "USER"],
      },
    },

    {
      path: "/gateway",
      name: "gateway",
      component: Gateway,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: ["ADMIN"],
      },
    },

    {
      path: "/gateway/add",
      name: "gateway-add",
      component: GatewayAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "gateway", label: "Coletores", link: "/gateways" },
          { name: "gateway-add", label: "Adicionar coletor", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/gateway/import",
      name: "gateway-import",
      component: GatewayImport,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "gateway", label: "Coletores", link: "/gateways" },
          { name: "gateway-import", label: "Importar coletor", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/gateway/StatusGateway",
      name: "gateways-status",
      component: GatewayStatus,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "gateway", label: "Coletores", link: "/gateways" },
          { name: "gateways-status", label: "Status coletor", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/gateway/:mac_address/config",
      name: "gateways-config",
      component: GatewayConfig,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "gateway", label: "Coletores", link: "/gateways" },
          { name: "gateways-config", label: "Configuração coletor", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/gateways/:mac_address/edit",
      name: "gateways-edit",
      component: GatewayEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "gateway", label: "Coletores", link: "/gateways" },
          { name: "gateway-add", label: "Editar coletor", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/tag",
      name: "tag",
      component: Tag,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: ["ADMIN"],
      },
    },

    {
      path: "/tag/add",
      name: "tag-add",
      component: TagAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "tag", label: "Tags", link: "/Tags" },
          { name: "tag-add", label: "Adicionar tags", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/tag/import",
      name: "tag-import",
      component: TagImport,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "tag", label: "Tags", link: "/Tag" },
          { name: "tag-import", label: "Importar Tag", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/tag/edit",
      name: "tag-edit",
      component: TagEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "tag", label: "Tags", link: "/Tags" },
          { name: "Tag-add", label: "Editar Tag", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },

    {
      path: "/group",
      name: "group",
      component: Group,
      beforeEnter: requireAuth,
    },
    {
      path: "/group/:groupName/edit",
      name: "group-edit",
      component: GroupEdit,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "group", label: "Grupos", link: "/group" },
          { label: "Editar grupo" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/group/:groupName/auth",
      name: "group-config",
      component: GroupConfig,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "group", label: "Grupos", link: "/group" },
          { label: "Configurações de autenticação" },
        ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/group/add",
      name: "group-add",
      component: GroupAdd,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "group", label: "Grupos", link: "/group" },
          { name: "group-add", label: "Adicionar grupo", link: "" },
        ],
        requiredRole: ["ADMIN"],
      },
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: ["DASHBOARD", "ADMIN", "SYSADMIN", "MANAGER", "EMPTY"],
      },
    },
    {
      path: "/customer/:customerId/notification-rule/add",
      name: "customer-add-notification-rule",
      component: AddNotificationRule,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Regras de notificação" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/customer/:customerId/customerconfig",
      name: "customer-config",
      component: CustomerConfig,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Configurações da Empresa" },
        ],
      },
    },
    {
      path: "/customer/:customerId/customerconfig/import",
      name: "customer-config-import",
      component: CustomerConfigImport,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { 
            name: "customer-config",
            label: "Configurações da Empresa",
            link: "/customer/:customerId/customerconfig",
          },
          { label: "Importar Configurações"}
        ],
      },
    },
    {
      path: "/customer/:customerId/timers",
      name: "customer-timers",
      component: TimersTab,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Configurações de horários" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN"],
      },
    },
    {
      path: "/customer/:customerId/notification-rule/:notificationRuleId/edit",
      name: "customer-edit-notification-rule",
      component: EditNotificationRule,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Regras de notificação" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "MANAGER"],
      },
    },
    {
      path: "/",
      name: "customer-home",
      component: Customer,
      beforeEnter: requireAuth,
    },
    {
      path: "/401",
      name: "Unauthorized",
      component: Unauthorized,
    },
    {
      path: "/communication",
      name: "communication",
      component: CanaisAtendimento,
    },
    {
      path: "/loading",
      name: "Loading",
      component: LoadingPage,
    },
    {
      path: "/platform",
      name: "platform",
      component: ManagerPlatform,
      beforeEnter: requireAuth,
      meta: {
        requiredRole: ["ADMIN", "SYSADMIN"],
      },
    },
    {
      path: "/platform/edit",
      name: "edit-platform",
      component: EditPlatform,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [

          { name: "platform", label: "Licenças", link: "/platform" },

          { label: "Controle de licenças" },

      ],
        requiredRole: ["ADMIN"],
      },
    },
    {
      path: "/customer/:customerId/addMonitoring",
      name: "add-monitoring",
      component: AddMonitoring,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Adicionar Monitoramento" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "DASHBOARD"],
      },
    },
    {
      path: "/customer/:customerId/monitoring/:monitoringId/edit",
      name: "edit-monitoring",
      component: EditMonitoring,
      beforeEnter: requireAuth,
      meta: {
        breadcrumb: [
          { name: "customer", label: "Clientes", link: "/customer" },
          {
            name: "customer-edit",
            label: "Editar cliente",
            link: "/customer/:customerId/edit",
          },
          { label: "Editar Monitoramento" },
        ],
        requiredRole: ["ADMIN", "SYSADMIN", "DASHBOARD"],
      },
    },
    {
      path: "/notification",
      name: "Notification",
      component: Notification,
    },
    {
      path: "/notification/add",
      name: "notification-add",
      component: NotificationAdd,
    },
    {
      path: "/notification/:notificationId/edit",
      name: "notification-edit",
      component: NotificationEdit,
    },    

  ],
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});

router.beforeEach(requireRole);

export default router;
