<template>
    <div v-can="(['ADMIN','SYSADMIN', 'DASHBOARD', 'USER'])">
      <MonitoringExclusion
        :entireMonitoring="monitoringTarget"
        @clearMonitoringTarget="handleMonitoring"
      />
      <MonitoringAlertsReason
        :customer="this.customer"
      />
      <b-modal v-if="assignedGeofencesModal" v-model="assignedGeofencesModal" hide-footer>
        <template #modal-header="{ close }">
          <h5>Geofences associadas</h5>
          <b-button variant="link" @click="close()">
            <span>X</span>
          </b-button>
        </template>
          <div>
            <b-table
              striped
              hover
              :items="monitoringTarget.geofences"
              show-empty
              empty-text="Nenhuma geofence associada."
              :fields="assignedGeofencesFields"
              ref="monitoring_geofences_table"
            >
            </b-table>
          </div>
      </b-modal>
      <Modal
        id="ModalDeleteMonitoring"
        title="Excluir Agendamento"
        :msg="'Tem certeza que deseja excluir ' + monitoringTarget?.name + '?'"
        style="white-space: pre-wrap"
        button="Excluir"
        :method="deleteMonitoring"
      />
      <div class="row px-0">
        <TwInput label="" :col_lg="hasRole(['USER']) ? '10' : '8'" class="pr-0">
          <b-input-group>
            <b-input-group-prepend is-text
              ><i class="fas fa-search"></i>
            </b-input-group-prepend>
            <input
            style="max-width: 900px"
              v-model="filter"
              type="search"
              class="form-control"
              id="filterInputMonitoring"
              placeholder="Buscar"
            />
          </b-input-group>
        </TwInput>
  
        <div 
          class="col-2 d-flex flex-column-reverse pb-3"
          :style="{
            display: hasRole(['USER']) ? 'none !important' : 'block',
          }"
        >
          <b-button
            href="#"
            variant="primary"
            title="Adicionar Agendamento de Pessoas na Linha"
            @click="
              () => {
                this.$router.push({ name: 'add-monitoring' });
              }
            "
            >Adicionar</b-button
          >
        </div>
        <div class="col-2 d-flex flex-column-reverse pb-3">
          <b-button
            id="alertModals"
            variant="primary"
            v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"
            href="#"
            title="Alertas gerados pelo monitoramento de pessoas"
            data-toggle="modal"
            :data-target="'#modalMonitoringAlerts'"
            @click="openMonitoringModal()"
            >Alertas</b-button
          >
        </div>
      </div>
      <div v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']">
        <b-table
          responsive="sm"
          striped
          borderless
          hover
          class="mt-3 rounded"
          no-sort-reset
          ref="selectableTable"
          sticky-header="610px"
          show-empty
          empty-text="Nenhum registro encontrado"
          empty-filtered-text="Nenhum registro encontrado para essa busca"
          head-variant="light"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :filter="filter"
          :busy="loadingCustomerGateways"
          :items="customerMonitorings"
          :fields="fieldsMonitoring"
          @filtered="onFiltered"
          @context-changed="changeBsTableState"
        >
          <template v-slot:table-colgroup>
            <col
              v-for="field in fieldsMonitoring"
              :key="field.key"
              :style="{
                width:
                  field.key === 'geofences' ||
                  field.key === 'enable' ||
                  field.key === 'actions' ||
                  field.key === 'exclusion' 
                    ? '100px' :
                  field.key === 'number_of_tags_enabled' 
                    ? '250px' 
                    : '550px',
              }"
            />
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>
          <template v-slot:cell(schedule)="data">
              <div>
                Iniciando em <span class="font-bold">{{ startDateAsVector(data.index)[0] }}</span>,<br/>
                Entre <span class="font-bold">{{ startDateAsVector(data.index)[1].slice(0, -3) }}</span> h até 
                <span class="font-bold">{{ endHourMonitoring(data.index) }}</span> h<br/>
                <span v-if="data.item.stop_date">
                  ,Encerrando em <span class="font-bold">{{ formatDateAPI(data.item.stop_date) }}</span>
                </span>
              </div>
          </template>
          <template v-slot:cell(frequency)="data">
              <div>
                Repetir a cada <span class="font-bold">{{ data.item.interval_of_repetition }}</span>
                <span class="font-bold"> {{ data.item.type_of_interval }}</span>,
                <div v-if="data.item.week_days">
                  {{ weekDaysString(data.index) }}
                </div>
              </div>
          </template>
          <template v-slot:cell(geofences)="data">
            <div style="text-align: center;">
              <a class="table-button active"
                @click="openGeofenceModal(data.item)"
                id="GeofencesMonitoringButton"
                title="Ver geofences associadas ao monitoramento"
                >
                <i class="fa-solid fa-location-dot table-icon"></i>
              </a>
            </div>
          </template>
          <template v-slot:cell(enable)="data">
            <div style="text-align: center;">
              <a 
                v-if="!hasRole('USER')"
                id="enableMonitoringButton"
                :class="`table-button ${data.item.status === 'ENABLED' ? 'active' : 'off'}`"
                @click="monitoringTarget = {...data.item}"
                data-toggle="modal"
                data-target="#ModalChangeState"
                :title="`${data.item.status === 'ENABLED' ? 'Desabilitar' : 'Habilitar'} monitoramento`"
              >
                <i class="fa-solid fa-toggle-on table-icon" v-if="data.item.status === 'ENABLED'"></i>
                <i class="fa-solid fa-toggle-off table-icon" v-else></i>
              </a>
              <div 
                v-else
                :class="`${data.item.status === 'ENABLED' ? 'table-actived-icon' : 'table-unactived-icon'}`"
                :title="`Monitoramento ${data.item.status === 'ENABLED' ? 'habilitado' : 'desabilitado'}`"
              >
                <i class="fa-solid fa-toggle-on table-icon" v-if="data.item.status === 'ENABLED'"></i>
                <i class="fa-solid fa-toggle-off table-icon" v-else></i>
              </div>
            </div>
          </template>
          <template v-slot:cell(exclusion)="data">
            <div style="text-align: center;">
              <a 
                id="exclusionMonitoringButton"
                :class="`table-button ${data.item.exclusions?.length > 0 ? 'active' : 'off'}`"
                title="Ver horários excluídos do monitoramento"
                @click="monitoringTarget = {...data.item}"
                data-toggle="modal"
                data-target="#modalMonitoringExclusion"
              >
                <i class="fa-regular fa-file-lines table-icon" v-if="data.item.exclusions?.length > 0"></i>
                <i class="fa-regular fa-file-excel table-icon" v-else></i>
              </a>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <a
            id="deleteMonitoring"
            v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
            @click="monitoringTarget = {...data.item}"
            href="#"
            class="mr-3"
            data-toggle="modal"
            :data-target="'#ModalDeleteMonitoring'"
            title="Excluir"
          >
            <i class="far fa-trash-alt iconspace"></i>
          </a>
          <router-link
            v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD']"
            :to="{
              name: 'edit-monitoring',
              params: {
                customerId: customer ? customer.id : -1,
                monitoringId: data.item.id,
              },
            }"
            title="Editar"
            id="customer-monitoring-edit-a"
          >
            <i id="editMonitoring" class="fas fa-pen iconspace"></i>
          </router-link>
          </template>
        </b-table> 
  
        <div class="row">
          <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="pagination"
            ></b-pagination>
          </div>
          <div class="col-12 col-md-8">
            <div class="btn-group" role="group">
              <b-dropdown
                variant="primary"
                v-model="perPage"
                :text="`Linhas: ${perPage}`"
              >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = 10;
                    }
                  "
                  >10</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = 20;
                    }
                  "
                  >20</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = 50;
                    }
                  "
                  >50</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = '1000';
                    }
                  "
                  >1000</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
  
        <Modal
          id="ModalChangeState"
          title="Alterar Estado"
          v-bind:msg="
            `Tem certeza que deseja alterar o estado de ${monitoringTarget?.name}?`
          "
          button="Alterar"
          buttonMod="info"
          :method="changeMonitoringState"
        />
  
        <div class="col-12">
          <router-link
            v-can="(['ADMIN', 'SYSADMIN', 'DASHBOARD'])"
            :to="{
              name: 'customer-add-gateways',
              params: { customerId: customer ? customer.id : -1 },
            }"
            id="addGateway-a"
          >
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Modal from "../../../components/Modal.vue";
  import TwInput from "@/components/TwInput.vue";
  import hasRole from "@/utils/hasRole";
  import MonitoringExclusion from "../../Monitoring/MonitoringExclusion.vue";
  import { formatDateAPI } from "../../../utils/format-fields";
  import MonitoringAlertsReason from "../../Monitoring/MonitoringAlertsReason.vue";
  
  export default {
    name: "CustomerMonitoringTab",
    data() {
      return {
        filter: null,
        perPage: 10,
        currentPage: 1,
        rows: 1,
        sortBy: "",
        assignedGeofencesModal: false,
        assignedMonitoringAlertsModal: false,
        fieldsMonitoring: [
          { label: "Nome", key: "name", sortable: true },
          { label: "Período de Agendamento", key: "schedule" },
          { label: "Frequência", key: "frequency" },
          { label: "Pessoas na Linha", key: "number_of_tags_expected" },
          { label: "Exceção", key: "exclusion" },
          { label: "Geofences", key: "geofences" },
          { label: "Estado", key: "enable" },
          { label: "Ações", key: "actions", class: "actions" },
        ],
        monitoringTarget: null,
        assignedGeofencesFields: [
          { label: "Geofence", key: "geofence_name", sortable: true }
        ],
      };
    },
    watch: {
      customer() {
        if (this.customer) {
          this.$store.dispatch("gateway/getCustomerGateways", {
            customer_id: this.customer.id,
          });
  
          this.$store.dispatch("monitoring/getMonitorings", {
            customer_id: this.customer.id,
          })
        }
      },
    },
    methods: {
      changeBsTableState(table_state) {
        if (this.$options.name) {
          localStorage.setItem(
            `bstable-state-${this.$options.name}`,
            JSON.stringify(table_state)
          );
        }
      },
      onFiltered(customerGateways) {
        this.rows = customerGateways.length;
      },
      hasRole,
      changeMonitoringState(){
        this.monitoringTarget.status = this.monitoringTarget.status === "ENABLED" ? "DISABLED" : "ENABLED"
        this.$store.dispatch("monitoring/changeMonitoringState", {
          customer_id: this.customer.id,
          monitoring_status: {
            id: this.monitoringTarget.id,
            status: this.monitoringTarget.status
          }
        })
      },
      openMonitoringModal(){
        this.assignedMonitoringAlertsModal = true;
      },
      openGeofenceModal(monitoring){
        this.monitoringTarget = {...monitoring};
        this.assignedGeofencesModal = true;
      },
      startDateAsVector(id){
        return this.customerMonitorings[id].start_date?.split(" ")
      },
      endHourMonitoring(id){
        this.weekDaysString(id)
        const newHour = this.hourFormatToMinutes(this.startDateAsVector(id)[1]) + this.customerMonitorings[id].duration
        return this.minutesToHourFormat(newHour)
      },
      formatDateAPI,
      deleteMonitoring(){
        if (this.monitoringTarget.geofences.length > 0){
          window.frontMsg(
            "FRNT_ACTION_WARNING",
            { reason: "o agendamento está associado a uma ou mais Geofences" }
          );
  
          return;
        }
        
        this.$store.dispatch("monitoring/deleteMonitoring", {
          customer_id: this.customer.id,
          monitoring_id: this.monitoringTarget.id
        })
      },
      hourFormatToMinutes(hour){
        let hourVector = hour.split(":");
        return parseInt(hourVector[0]) * 60 + parseInt(hourVector[1])
      },
      minutesToHourFormat(minutes){
        let hourFormat = Math.floor(minutes/60);
        let minutesFormat = minutes % 60;
  
        hourFormat = hourFormat > 23 ? hourFormat - 24 : hourFormat;
        hourFormat = hourFormat < 10? "0" + hourFormat : hourFormat;
        minutesFormat = minutesFormat < 10? "0" + minutesFormat : minutesFormat;
  
        return `${hourFormat}:${minutesFormat}`
      },
      weekDaysString(id){
        const weekDaysObj = [
          "Segundas-Feiras",
          "Terças-Feiras",
          "Quartas-Feiras",
          "Quintas-Feiras",
          "Sextas-Feiras",
          "Sábados",
          "Domingos"
        ]
  
        if (!this.customerMonitorings[id].week_days || this.customerMonitorings[id].week_days?.length === 0){
          return "";
        }
  
        else if (this.customerMonitorings[id].week_days?.length === 7){
          return "Todos os dias."
        }

        else {
          const noWeekendDaysLength = this.customerMonitorings[id].week_days.filter((day) => day < 5).length
  
          let phrase = noWeekendDaysLength > 0 ? "Todas as " : "Todos os "
  
          for (let i in this.customerMonitorings[id].week_days){
            if (
                i == this.customerMonitorings[id].week_days.length - 1 && 
                this.customerMonitorings[id].week_days.length > 1
            ){
              phrase = phrase + ' e ';
            }
  
            else if (i > 0) {
              phrase = phrase + ', ';
            }
            
            phrase = phrase + weekDaysObj[this.customerMonitorings[id].week_days[i]]
          }
          
          return phrase + ".";
        }
      },
      handleMonitoring(newValue){
        for (const prop in this.monitoringTarget){
          delete this.monitoringTarget[prop]
        }
  
        this.monitoringTarget = null;
      }
    },
    computed: {
      customer() {
        return this.$store.getters["customer/customer"];
      },
      customerMonitorings() {
        return this.$store.getters["monitoring/monitorings"];
      },
      loadingCustomerGateways() {
        return this.$store.getters["gateway/loadingCustomerGateways"];
      },
    },
    components: {
      Modal,
      TwInput,
      MonitoringExclusion,
      MonitoringAlertsReason,
    },
  };
  </script>
  
  <style scoped>
    .table-icon{
      font-size: 20px;
    }
  
    .font-bold{
      font-weight: bold;
    }
  
    .table-button{
      color: #6610f2;
      cursor: pointer;
    }
  
    .table-actived-icon{
      color: #6610f2;
    }
    .table-unactived-icon{
      color: gray;
    }

    .table-button.active:hover{
      color: #a471f7;
    }
  
    .table-button.off{
      color: gray;
    }
  
    .table-button.off:hover{
      color: #BBB;
    }
  
    .table-button.inactive{
      color: gray;
      cursor:auto;
    }
  </style>